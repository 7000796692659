@import '@ng-select/ng-select/themes/default.theme.css';
@import 'libs/shared/styles/src/lib/icons';
@import 'theme/material';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'theme/variables';
@import 'theme/psp_variables';
@import 'components/home-tabs';

body {
  font-family: 'Roboto', sans-serif;

  &.search-view {
    overflow: hidden;

    ps-root {
      overflow: hidden;
    }

    header {
      display: none;
    }
  }

  &.dev::after {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url('/assets/images/staging_watermark.svg');
    opacity: 0.1;
    pointer-events: none;
    z-index: 1001;
  }

  main {
    margin-top: 120px;
  }
}

.dropdown-item {
  white-space: normal;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #1c70b8;
}

.spinner-container {
  text-align: center;
}

.spinner-border.spinner-large.spinner {
  width: 5em;
  height: 5em;
  color: $blue-1;
}

.hint-highlight {
  font-weight: bold;
  color: #797982;
}

a.search-result {
  &:hover {
    .hint-highlight {
      color: $search-font-color;
    }
  }
}

.ps-icon {
  display: block;
  color: $main-font-color;
}

.catalog-category-items {
  .search-view & {
    position: fixed;
    z-index: 999;
    top: 75px;
    left: 15px;
    right: 15px;
    bottom: 0;
    overflow: auto;
  }
}

.organization-wrapper {
  .search-view & {
    position: fixed;
    z-index: 999;
    top: 40px;
    left: 15px;
    right: 15px;
    bottom: 0;
    overflow: auto;
  }
}

.cdk-overlay-pane {
  .mat-optgroup-label {
    padding: 0;
  }

  .mat-optgroup .mat-option:not(.mat-option-multiple) {
    padding-left: 16px;
  }

  .search-view & {
    bottom: 0;

    .mat-optgroup .mat-option:not(.mat-option-multiple) {
      padding-left: 4px;
      padding-right: 4px;
    }

    .mat-autocomplete-visible {
      box-shadow: none;
    }

    .mat-option {
      .mat-option-text {
        .text-wrapper {
          display: flex;
          align-items: center;

          .text {
            flex: 1;
          }

          .ps-icon {
            display: block;
            color: $description-font-color;
            font-size: $font-size-base;
          }
        }
      }
    }
  }

  .mat-autocomplete-panel {
    background: white;
    max-height: 100%;
    margin-top: 5px;
    border-radius: 6px;
    box-shadow: 0 16px 80px 0 rgba(1, 87, 155, 0.2);
    padding: 15px;

    .mat-optgroup-label {
      line-height: 32px;
      height: 32px;
      margin-bottom: 4px;
      color: $secondary-font-color;
      border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    }
  }

  .mat-option {
    white-space: inherit;
    line-height: inherit;
    height: auto;
    padding: 5px 16px;
    word-break: break-word;

    &.mat-active {
      background-color: rgba(2, 119, 189, 0.06);
    }

    &:active,
    &:hover,
    &:focus {
      background-color: rgba(2, 119, 189, 0.06);
    }

    .mat-option-text {
      color: $description-font-color;

      .hint-highlight {
        color: $description-font-color;
      }

      .ps-icon {
        display: none;
      }
    }
  }
}

a[psAnchorLinkToRoute] {
  color: #007bff;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
  }
}

.catalog-details-title {
  font-size: $mstyle-h3-font-size;
  font-weight: normal;
  color: $main-font-color;
  margin-top: 2px;
  margin-bottom: 16px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 8px;
  }
}

.catalog-details-description {
  font-size: $font-size-sm;
  color: $default-text-color;

  @include media-breakpoint-down(xs) {
    p {
      margin-bottom: 8px;
    }
  }
}

.catalog {
  .search-filter {
    display: block;
    margin-bottom: 16px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }
}

.top-navbar {
  .dropdown-menu {
    padding: 4px 0;
    min-width: 100%;
    width: max-content;

    .dropdown-item {
      padding: 4px 16px;
      font-size: $font-size-sm;
      color: $dropdown-menu-font-color;
      cursor: pointer;

      &.active,
      &:active,
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.organization-info {
  .inline-link-list-inline-link {
    font-size: $font-size-sm;
    color: $organization-link-contact-font-color;
  }
}

@include media-breakpoint-down(sm) {
  body {
    main {
      margin-top: 100px;
    }
  }
}

a {
  word-break: break-word;
}

.collapse-content-description {
  text-indent: 16px;
  color: $default-text-color;
  line-height: 1.714em;
  font-size: $psp-font-size-small;
}

.grecaptcha-badge {
  visibility: hidden;
}

.btn-outline {
  color: #4498e0;
  font-weight: bold;
  text-align: center;

  border: 1px solid #4498e0;
  border-radius: 6px;
  padding: 8px;
  margin: 0;
}

.filter-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  mask: url('/assets/icons/filter-solid.svg');
  mask-size: cover;
  background: $main-font-color;
}

.fixed-top {
  z-index: 999;
}

.sticky-top {
  z-index: 0;
}

.btn {
  &.more-link,
  &.more-btn {
    text-transform: uppercase;
  }
}

@include media-breakpoint-down(sm) {
  .home-tabs {
    .nav-justified > .nav-link,
    .nav-justified .nav-item {
      flex-basis: unset;
    }
  }
}

.doc-attachments {
  text-align: right;
}
