@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat.core();

// Define the theme.
$candy-app-primary: mat.define-palette(mat.$indigo-palette, 500); //notice that functions are prefixed with Namespace mat. This is a feature of the scss @use  directive
$candy-app-accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
//$candy-app-theme:   mat.define-light-theme($candy-app-primary, $candy-app-accent);
$candy-app-warn:    mat.define-palette(mat.$red-palette);

$candy-app-theme-config: (
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn
  ),
  typography: mat.define-typography-config(), // Default typography, customize as needed
  density: null,  // Optional, define if needed
);

// Define the theme
$candy-app-theme: mat.define-light-theme($candy-app-theme-config);
// Include the theme styles for only specified components.
/*@include mat-core-theme($candy-app-theme);*/
@include mat.autocomplete-theme($candy-app-theme);
