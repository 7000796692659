@import 'psp_variables';

@mixin nav-item-state() {
  color: $tab-active-font-color;
  background-color: $nav-tabs-link-active-bg;
  border-color: $home-tabs-link-active-border-color;
  font-weight: $tab-active-font-weight;
}

$summary-tabs-border-width: 1px;
$summary-tabs-padding: 17px;

.home-tabs {
  .nav-tabs {
    position: relative;
    margin-top: calc(
      -#{$nav-link-padding-x * 2 + $font-size-base * $line-height-base} - 3px
    );
    border-bottom: $nav-tabs-border-width solid $home-tabs-border-color;

    .nav-item {
      margin-bottom: -2px;

      .nav-link {
        margin-right: 1px;
        margin-left: 1px;
      }
    }

    .nav-link {
      background-color: rgba(255, 255, 255, 0.3);
      padding: $nav-link-padding-x;
      color: $tab-font-color;
      position: relative;

      @media (min-width: 0) and (max-width: 480px) {
        padding: $nav-link-padding-x 0;
      }

      @include hover-focus() {
        z-index: 4;
        @include nav-item-state();
        border-bottom: 2px solid white;
      }

      @include border-top-radius($home-tabs-border-radius);

      &.active {
        cursor: default;
        border-bottom: 1px solid white;
        padding-bottom: calc(
          #{$nav-link-padding-x} + #{$nav-tabs-border-width} + 1px
        );
        position: relative;

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 7px;
          width: 20px;
          bottom: 0;

          @include media-breakpoint-down(xs) {
            content: none;
          }
        }

        &:after {
          right: -20px;
          border-bottom-left-radius: 10px;
          box-shadow: -10px 0 0 0 #fff;
        }

        &:before {
          left: -20px;
          border-bottom-right-radius: 10px;
          box-shadow: 10px 0 0 0 #fff;
        }
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      @include nav-item-state();
    }

    @include media-breakpoint-down(xs) {
      font-size: $font-size-sm;
      margin-left: -10px;
      margin-right: -10px;

      margin-top: calc(
        -#{0.6rem * 2 + $font-size-sm * $line-height-base} - 2px
      );

      .nav-link {
        padding: 0.6rem 0;

        &.active {
          padding-bottom: calc(#{0.6rem} + 1px);
        }
      }
    }
  }
}
