@import 'psp_variables';

@font-face {
  font-family: 'PSP_24';
  src: url('/assets/icons/PSP_24.eot?skgrhs');
  src: url('/assets/icons/PSP_24.eot?skgrhs#iefix') format('embedded-opentype'),
    url('/assets/icons/PSP_24.ttf?skgrhs') format('truetype'),
    url('/assets/icons/PSP_24.woff?skgrhs') format('woff'),
    url('/assets/icons/PSP_24.svg?skgrhs#PSP_24') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='ps-icon-'],
[class*=' ps-icon-'] {
  /* use !important to prevent issues with browser extensions that change /assets/icons */
  font-family: 'PSP_24' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: $main-font-color;
  font-size: 1.5rem;

  a:hover & {
    text-decoration: none;
  }

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ps-icon-event-death:before {
  content: "\e900";
}
.ps-icon-call:before {
  content: "\e901";
}
.ps-icon-chain:before {
  content: "\e902";
}
.ps-icon-chec-1:before {
  content: "\e903";
}
.ps-icon-chec-2:before {
  content: "\e904";
}
.ps-icon-Chevron-down:before {
  content: "\e905";
}
.ps-icon-Chevron-left:before {
  content: "\e906";
}
.ps-icon-Chevron-right:before {
  content: "\e907";
}
.ps-icon-Chevron-up:before {
  content: "\e908";
}
.ps-icon-clock:before {
  content: "\e909";
}
.ps-icon-close:before {
  content: "\e90a";
}
.ps-icon-doc:before {
  content: "\e90b";
}
.ps-icon-email:before {
  content: "\e90c";
}
.ps-icon-event-1:before {
  content: "\e90d";
}
.ps-icon-event-2:before {
  content: "\e90e";
}
.ps-icon-event-3:before {
  content: "\e90f";
}
.ps-icon-event-4:before {
  content: "\e910";
}
.ps-icon-events:before {
  content: "\e911";
}
.ps-icon-info:before {
  content: "\e912";
}
.ps-icon-location:before {
  content: "\e913";
}
.ps-icon-public:before {
  content: "\e914";
}
.ps-icon-search:before {
  content: "\e915";
}
.ps-icon-service-electronic:before {
  content: "\e916";
}
.ps-icon-service:before {
  content: "\e917";
}
.ps-icon-unload:before {
  content: "\e918";
}
.ps-icon-watch:before {
  content: "\e919";
}
.ps-icon-accessible:before {
  content: "\e91a";
}
.ps-icon-birth:before {
  content: "\e91b";
}
.ps-icon-book:before {
  content: "\e91c";
}
.ps-icon-business:before {
  content: "\e91d";
}
.ps-icon-child:before {
  content: "\e91e";
}
.ps-icon-contactless:before {
  content: "\e91f";
}
.ps-icon-drive:before {
  content: "\e920";
}
.ps-icon-events-2:before {
  content: "\e921";
}
.ps-icon-flags:before {
  content: "\e922";
}
.ps-icon-house:before {
  content: "\e923";
}
.ps-icon-justice:before {
  content: "\e924";
}
.ps-icon-local_hospital:before {
  content: "\e925";
}
.ps-icon-marriage:before {
  content: "\e926";
}
.ps-icon-money:before {
  content: "\e927";
}
.ps-icon-more:before {
  content: "\e928";
}
.ps-icon-passport:before {
  content: "\e929";
}
.ps-icon-population:before {
  content: "\e92a";
}
.ps-icon-school:before {
  content: "\e92b";
}
.ps-icon-storefront:before {
  content: "\e92c";
}
.ps-icon-swap:before {
  content: "\e92d";
}
.ps-icon-trending:before {
  content: "\e92e";
}
.ps-icon-work:before {
  content: "\e92f";
}
.ps-icon-event-building:before {
  content: "\e930";
}
.ps-icon-event-business-1:before {
  content: "\e931";
}
.ps-icon-event-business-2:before {
  content: "\e932";
}
.ps-icon-event-business-3:before {
  content: "\e933";
}
.ps-icon-event-car:before {
  content: "\e934";
}
.ps-icon-event-disability:before {
  content: "\e936";
}
.ps-icon-event-drivers-license:before {
  content: "\e937";
}
.ps-icon-event-flag:before {
  content: "\e938";
}
.ps-icon-event-health:before {
  content: "\e939";
}
.ps-icon-event-help:before {
  content: "\e93a";
}
.ps-icon-event-house:before {
  content: "\e93b";
}
.ps-icon-event-marriage:before {
  content: "\e93c";
}
.ps-icon-event-passport:before {
  content: "\e93d";
}
.ps-icon-event-pension:before {
  content: "\e93e";
}
.ps-icon-event-pregnant:before {
  content: "\e93f";
}
.ps-icon-event-student:before {
  content: "\e940";
}
.ps-icon-event-taxes:before {
  content: "\e941";
}
.ps-icon-event-unemployment:before {
  content: "\e942";
}
.ps-icon-avatar:before {
  content: "\e943";
}
.ps-icon-filter:before {
  content: "\e944";
}
.ps-icon-language:before {
  content: "\e945";
}
.ps-icon-login:before {
  content: "\e946";
}
.ps-icon-menu:before {
  content: "\e947";
}
.ps-icon-stema:before {
  content: "\e948";
}
