@import 'variables';

$blue-1: #0277bd;
$blue-2: #01579b;
$blue-3: #0091ea;
$blue-4: #3366cc;
$blue-5: #00569a;
$blue-6: #4498e0;
$blue-7: #0288d1;
$grey-1: #767c8d;
$grey-2: #9499a9;
$grey-3: #cccccc;

$default-text-color: #666666;
$header-default-text-color: #333333;

$mstyle-h1-font-size: $font-size-base * 2.25;
$mstyle-h2-font-size: $font-size-base * 1.875;
$mstyle-h3-font-size: $font-size-base * 1.5;
$mstyle-h4-font-size: $font-size-base * 1.125;
$mstyle-h5-font-size: $font-size-base * 0.875;
$mstyle-h6-font-size: $font-size-base * 0.875;

$psp-font-size-small: $font-size-base * 0.875;

$psp-font-size-base-plus: $font-size-base * 1.125;

$psp-font-size-tiny: $font-size-base * 0.75;

/*$main-font-color: $blue-1;*/
$main-font-color: $blue-3;

$secondary-font-color: $blue-2;

$header-font-color: $white;

$event-font-color: $blue-3;

$not-found-font-color: $blue-3;

$about-background: #f5fafd;

$main-nav-font-color: $blue-1;

$description-font-color: $grey-1;

$not-found-page-description-color: $grey-2;

$faq-border-color: $main-font-color;

$faq-title-font-color: $secondary-font-color;

$tab-active-font-color: $secondary-font-color;
$tab-active-font-weight: $font-weight-bold;
$home-tabs-border-radius: 5px;
$tab-font-color: $white;
$home-tabs-border-color: $blue-4;
$home-tabs-link-active-border-color: $home-tabs-border-color
  $home-tabs-border-color $nav-tabs-link-active-bg !default;

$top-navbar-background-color: $blue-2;
$top-navbar-lang-hover: #e9eeff;

$jumbotron-background-color: #c2d1d9;

$branding-blue-background-border-font-color: $blue-1;
$branding-blue-header-color: $blue-2;
$search-font-color: $blue-3;

$page-font-size-title: $font-size-base * 1.3125;
$mobile-font-size-title: 1.1rem;
$catalog-page-font-size-title: $page-font-size-title;

$breadcrumb-font-color: $grey-1;

$psp-btn-background: $blue-1;

$top-nav-text-color: white;

$dropdown-menu-font-color: $blue-5;

$search-filter-border-color: $blue-1;

$organization-link-contact-font-color: $blue-6;

$secondary-border-color: $grey-3;

$catalog-event-font-color: $blue-7;
